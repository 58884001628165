import Vue from 'vue';
import Vant from 'vant';
import 'vant/lib/index.css';
import { Dialog } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';

Vue.use(Vant);
Vue.use(Form);
Vue.use(Field);

export default {
    name: "gnrhPhysicalInput",
    data() {
        return {
            token: '',
            patientUuid: '',
            // loading: true,
            // labelPosition: 'left',
            gender: 2,

            issubmit: false,

            gnrhPhysicalForm: {

            },
            rules: {

            }
        }
    },
    created() {
        // this.loading = false;
    },
    mounted() {
        this.token = this.$route.query.token;
        this.patientUuid = this.$route.query.patientUuid;

        if(this.patientUuid === null || this.patientUuid === '') {
            Dialog.alert({
                title: '提示',
                message: '抱歉，您还未登录，请先登录再进行操作。',
            })
        } else {
            this.checkGender();
        }
    },
    methods: {
        checkGender() {
            let that = this;
            that.$axios({
                method: "get",
                url: "/api/api/user/detail/" + that.patientUuid,
            }).then(res => {
                console.log("res", res);
                if(res.data.code === 200) {
                    if(res.data.data !== null && res.data.data.gender !== null) {
                        // 男
                        that.gender = res.data.data.gender;
                    } else if (res.data.data.gender === null) {
                        Dialog.confirm({
                            title: '提示',
                            message: '请选择您的性别',
                            confirmButtonText: '男性',
                            cancelButtonText: '女性'
                        })
                            .then(() => {
                                // 男
                                that.gender = 1;
                                that.updateUser(1);
                            })
                            .catch(() => {
                                // 女
                                that.gender = 0;
                                that.updateUser(0);
                            });
                    } else {
                        that.$message({
                            message: '用户不存在',
                            type: "error"
                        });
                    }
                }
            });
        },
        updateUser(gParam) {
            this.$axios({
                method: "post",
                url: "/api/api/user/update",
                data: {
                    uuid: this.patientUuid,
                    gender: gParam
                }
            }).then(res => {
                if(res.data.code !== 200) {
                    this.$message({
                        message: res.data.message,
                        type: "error",
                        onClose: () => {
                            this.gender = 2;
                        }
                    });
                }
            })
        },
        onSubmit() {
            let that = this;
            that.issubmit = true;

            that.$set(that.gnrhPhysicalForm, "userUuid", that.patientUuid);
            that.$set(that.gnrhPhysicalForm, "gender", that.gender);

            that.$axios({
                method: "post",
                url: "/api/api/patientGnrhPhysicalRecord/create",
                data: that.gnrhPhysicalForm
            }).then(res => {
                if(res.data.code === 200) {
                    that.$message({
                        message: "保存成功！",
                        type: "success",
                        onClose: () => {
                            that.issubmit = false;
                            that.$router.push({
                                path: "/gplist",
                                query: {
                                    patientUuid: that.patientUuid
                                }
                            });
                        }
                    });
                } else {
                    that.$message({
                        message: res.data.message,
                        type: "error"
                    });
                }
            });
        },
        submitForm(formName) {
            console.log(formName);
            // let that = this;
            // that.$refs[formName].validate(valid => {
            //     if (!valid) {
            //         return false;
            //     }
            //
            //     that.$set(that.gnrhPhysicalForm, "userUuid", that.patientUuid);
            //     that.$set(that.gnrhPhysicalForm, "gender", that.gender);
            //
            //     that.$axios({
            //         method: "post",
            //         url: "/api/patientGnrhPhysicalRecord/create",
            //         data: that.gnrhPhysicalForm
            //     }).then(res => {
            //         if(res.data.code === 200) {
            //             this.$message({
            //                 message: "保存成功！",
            //                 type: "success"
            //             });
            //         } else {
            //             this.$message({
            //                 message: res.data.message,
            //                 type: "error"
            //             });
            //         }
            //     })
            // });
        }
    }
}
